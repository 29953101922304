import './layui'

let layui = window.layui
let $ = layui.$;

echarts = window.echarts;
let chartDom = document.getElementById('rightmain');
let myChart = echarts.init(chartDom);
let allgongshi;

function func(x) {
    x /= 10;
    return Math.sin(x) * Math.cos(x * 2 + 1) * Math.sin(x * 3 + 2) * 50;
}

let option = {series: []}

let setoption = {
    legend: {},
    animation: false,
    grid: {
        top: 80,
        left: 50,
        right: 50,
        bottom: 20,

        containLabel: true,
        aspectRatio: 1  // 设置宽高比为1，保证x轴和y轴比例相同

    },
    xAxis: {
        name: 'x',
        min: -40,
        max: 40,
        minorTick: {
            show: true
        },
        minorSplitLine: {
            show: true
        }
    },
    yAxis: {
        name: 'y',
        min: -40,
        max: 40,
        minorTick: {
            show: true
        },
        minorSplitLine: {
            show: true
        }
    },
    dataZoom: [
        {
            show: true,
            type: 'inside',
            filterMode: 'none',
            xAxisIndex: [0],
            startValue: -10,
            endValue: 10
        },
        {
            show: true,
            type: 'inside',
            filterMode: 'none',
            yAxisIndex: [0],
            startValue: -10,
            endValue: 10
        }
    ],
    series: [
        // {
        //     type: 'line',
        //     showSymbol: false,
        //     clip: true,
        //     data: generateData()
        // }
    ]
};

setoption && myChart.setOption(setoption);

function resize() {
    var rightmain = document.getElementById('rightmain');
    var windowWidth = window.innerWidth; // 窗口宽度
    var windowHeight = window.innerHeight; // 窗口高度
    var size = Math.min(windowWidth, windowHeight); // 取最小值作为正方形的边长

    // 设置 div 的宽度和高度为最小值
    rightmain.style.width = size + 'px';
    rightmain.style.height = size + 'px';
    myChart.resize();
}

resize();
window.addEventListener('resize', function () {
    resize()
});

Math.sqrt = function (number) {
    if (Math.abs(number) < 0.000001) {
        return 0; // 处理接近零的情况，返回 0
    } else if (number < 0) {
        return NaN; // 处理负数的情况，返回 NaN
    } else {
        // 其他情况，调用原生 Math.sqrt 函数
        return number ** 0.5;
    }
};

let gongshilist = [];
let colors = ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc']

// function createFunction(jsonData, fuhao) {
//     var functionBody = 'return ' + jsonData + ';';
//     return new Function(fuhao, functionBody);
// }

function createFunction(fn, fuhao) {
    let func;
    let text = 'func=function (' + fuhao + '){return eval(fn);}';
    // console.log(text);
    eval(text);
    // console.log(func)
    return func
}

function formatZero(number) {
    if (number === 0 && 1 / number === -Infinity) {
        return 0;
    }
    return number;
}

function updatedata(datajson) {
    option.series = [];
    myChart.setOption(option);
    for (let j in datajson) {
        if (j < datajson.length) {
            let djson;
            let fuhao;
            let canshu;
            for (let k = 0; k < 2; k++) {
                // try {
                //     if (k === 0) {
                //         console.log(datajson[j])
                //         if (datajson[j].y && datajson[j].y.length > 0) {
                //             djson = datajson[j].y;
                //             fuhao = 'y';
                //             canshu = 'x';
                //         } else {
                //             continue;
                //         }
                //     }
                //     if (k === 1) {
                //         if (datajson[j].x && datajson[j].x.length > 0) {
                //             djson = datajson[j].x;
                //             fuhao = 'x';
                //             canshu = 'y';
                //         } else {
                //             continue;
                //         }
                //     }
                // }
                // catch (e) {
                //     continue;
                // }
                djson = datajson[j];
                fuhao = 'y';
                canshu = 'x';
                console.log('djson', djson)
                for (let index in djson) {
                    let xulie = djson[index]
                    let jssuanshi = xulie[fuhao];
                    console.log('jssuanshi', jssuanshi)
                    console.log('canshu', canshu)

                    let func = createFunction(jssuanshi, canshu);
                    let data = [];
                    try {
                        for (let i = -40; i <= 40; i += 0.1) {

                            if (fuhao === 'y') {
                                let jsuan = parseFloat(func(formatZero(i)).toFixed(5));
                                if (Math.abs(jsuan) > 400) {
                                    jsuan = 'a';
                                }
                                data.push([i, jsuan]);
                            } else {
                                let jsuan = parseFloat(func(formatZero(i)).toFixed(5));
                                if (Math.abs(jsuan) > 400) {
                                    jsuan = 'a';
                                }
                                data.push([jsuan, i]);
                            }
                        }
                    } catch (e) {
                        console.log('计算出错');
                        continue;
                    }
                    let se = {
                        type: 'line',
                        name: canshu,
                        showSymbol: false,
                        clip: true,
                        data: data,
                        lineStyle: {
                            color: colors[j % 9]
                        }
                    }
                    // console.log(data);
                    option.series.push(se)
                }
            }
        } else {
            let jies = datajson[j];
            let jielen = jies.length;
            console.log('一共', jielen, `组解`);
            for (let index in jies) {
                let jie = jies[index];
                try {
                    let x = createFunction(jie['x_j'], 'x')(1);
                    let y = createFunction(jie['y_j'], 'y')(1);
                    console.log('解', parseInt(index) + 1, `:`, x, y);
                    console.log('解', parseInt(index) + 1, `式子表示: x=`, jie['x'], '   ||  y=', jie['y']);

                    let se = {
                        name: "解" + (parseInt(index) + 1),
                        type: 'scatter',
                        data: [
                            // 添加单独的点
                            [x, y],
                            // 可以继续添加其他点
                        ],
                        symbol: 'circle', // 指定点的样式
                        symbolSize: 8, // 指定点的大小
                    }
                    option.series.push(se);
                } catch (e) {
                    console.log('解', parseInt(index) + 1, `:有复数`);
                    console.log('解', parseInt(index) + 1, `式子表示: x=`, jie['x'], '   ||  y=', jie['y']);
                }

            }
        }

    }
    myChart.getOption().series = [];
    myChart.setOption(option);
}

function getgongshi(text) {
    var data = {
        fc: text,
        var: ""
    };

    $.ajax({
        url: "http://localhost:5000/solve_equations",
        type: "POST",
        dataType: "json",
        contentType: "application/json",
        data: JSON.stringify(data),
        success: function (data) {
            // 请求成功后的处理
            console.log(data);
            updatedata(data);

        },
        error: function (xhr, status, error) {
            // 请求失败时的处理
            console.log("请求失败: " + error);
        }
    });
}

myChart.on('datazoom', function (params) {
    var xAxisIndex = params.batch[0].xAxisIndex; // 获取当前缩放事件中涉及到的 xAxisIndex
    var yAxisIndex = params.batch[0].yAxisIndex; // 获取当前缩放事件中涉及到的 yAxisIndex

    if (xAxisIndex !== undefined && yAxisIndex !== undefined) {
        var xAxis = myChart.getModel().getComponent('xAxis', xAxisIndex);
        var yAxis = myChart.getModel().getComponent('yAxis', yAxisIndex);

        var xDataZoom = xAxis.option.dataZoom;
        var yDataZoom = yAxis.option.dataZoom;

        var xStart = xDataZoom[0].start;
        var xEnd = xDataZoom[0].end;
        var yStart = yDataZoom[0].start;
        var yEnd = yDataZoom[0].end;

        var xRange = xEnd - xStart;
        var yRange = yEnd - yStart;

        if (xRange !== yRange) {
            if (xRange > yRange) {
                var newEnd = yStart + xRange;
                if (newEnd <= 100) {
                    yDataZoom[0].end = newEnd;
                } else {
                    yDataZoom[0].start = 100 - xRange;
                    yDataZoom[0].end = 100;
                }
            } else {
                var newEnd = xStart + yRange;
                if (newEnd <= 100) {
                    xDataZoom[0].end = newEnd;
                } else {
                    xDataZoom[0].start = 100 - yRange;
                    xDataZoom[0].end = 100;
                }
            }

            myChart.setOption({
                xAxis: xAxis.option,
                yAxis: yAxis.option
            });
        }
    }
});
layui.use([], function () {

    // $('.layui-btn').on('click', function () {
    //     // var textareaContent = $('#gongshilist').val().trim();
    //     // allgongshi = textareaContent.split('\n');
    //     // allgongshi = allgongshi.filter(function(gongshi) {
    //     //     return gongshi.trim() !== "";
    //     // });
    //     // console.log(allgongshi);
    //     //
    //     // getgongshi(allgongshi);
    //
    //
    // })
    $('.layui-btn').on('click', function () {
        // 获取用户选择的图片
        var img = $('#image-upload').get(0).files[0];
        if (!img) {
            alert('请先选择一张图片');
            return;
        }
        var reader = new FileReader();
        // 设置读取完成后的回调函数
        reader.onload = function (e) {
            // e.target.result 就是读取到的图片的Data URL
            $('#imgmain').attr('src', e.target.result);
        };
        // 读取图片文件
        reader.readAsDataURL(img);

        // 创建FormData对象
        var formData = new FormData();
        formData.append('image', img);
        formData.append("isolated_sep", " , ")
        // 使用$.ajax进行图片上传
        $.ajax({
            url: 'https://fc.yukicv.com/i2l/pix2text', // 服务器端的上传接口地址
            type: 'POST',
            data: formData,
            contentType: false, // 必须
            processData: false, // 必须
            success: function (data) {
                // 这里处理上传成功后的逻辑
                console.log(data); // 打印返回的JSON数据
                updatedata([data['alldata']]);
                $('#gongshi').html('$$' + data['results'][0]['text'] + '$$')

                window.MathJax.typeset();
                // 根据需要在这里进行进一步的处理
            },
            error: function (jqXHR, textStatus, errorThrown) {
                // 这里处理上传失败后的逻辑
                console.error('上传失败： ' + textStatus + ', ' + errorThrown);
            }
        });
    });
});
